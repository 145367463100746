<script lang="ts" setup>
</script>

<template>
  <header
    class="
        fixed
        top-0
        z-40
        flex
        h-[--header-height]
        w-screen
        items-center
        border-b
        border-border
        bg-background
        px-4
        py-2
        "
  >
    <slot />
  </header>
</template>
